import Slider from "../components/Slider";
import Header from "../components/Header";
import Descripcion from "../components/Descripcion";

const Home = () => (
  <div id="inicio" className="home">
    <Header />
    <Slider />
    <Descripcion />
  </div>
);

export default Home;
