import { useState } from "react";
import "../assets/styles/Components/ConfItem.css";

import { Card, ListGroup, Button, Modal } from "react-bootstrap";
import ModalA from "./ModalA";

const ConfItem = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    foto,
    actividad,
    persona,
    fecha,
    semestre,
    horario,
    resena,
    aula,
    titulo,
    tipo,
  } = props;
  return (
    <>
      <Card className="Tarjeta">
        <Card.Img
          variant="top"
          src={require(`../assets/img/conferencistas/${foto[0]}`)}
          className="Tarjeta-img"
          id="#fotoaver"
        />
        <Card.Body>
          <Card.Title>{actividad}</Card.Title>
          <ListGroup.Item>{persona}</ListGroup.Item>
          <ListGroup.Item>{fecha}</ListGroup.Item>
          <ListGroup.Item>{semestre}</ListGroup.Item>
          <ListGroup.Item>{horario}</ListGroup.Item>
        </Card.Body>
        <Card.Footer>
          <Button id="botonchido" variant="primary" onClick={handleShow}>
            Detalles
          </Button>
        </Card.Footer>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalA
            foto={foto}
            conferencia={actividad}
            persona={persona}
            titulo={titulo}
            fecha={fecha}
            hora={horario}
            resena={resena}
            aula={aula}
            semestre={semestre}
            tipo={tipo}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfItem;
