import React from "react";
import ConfItem from "../components/ConfItem";
import { Row, Col } from "react-bootstrap";

import conferencias from "../../conferencias.json";
import "../assets/styles/Conf.css";

class Conf extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, error: null, data: [], key: 0 };
  }

  componentDidMount() {
    this.setState({ loading: false, data: conferencias });
  }
  render() {
    return (
      <Row xs={1} md={3} className="g-3">
        {conferencias[`${this.props.name}`].map((item) => (
          <Col key={item.id}>
            <ConfItem
              key={item.id}
              foto={item.foto}
              actividad={item.actividad}
              persona={item.conferencista}
              fecha={item.fecha}
              semestre={item.semestre}
              horario={item.horario}
              resena={item.resena}
              aula={item.aula}
              titulo={item.titulo}
              tipo={item.tipo}
            />
          </Col>
        ))}
      </Row>
    );
  }
}

export default Conf;
