import Congreso from "../assets/img/congreso.png";
import Itsm from "../assets/img/itsm.png";

import "../assets/styles/Components/Header.css";

const Header = () => (
  <div className="Header">
    <div className="BlurCompa">
      <img src={Itsm} alt="Logo del Congreso Numero 8" />
      <img src={Congreso} alt="Logo del Congreso Numero 8" />
    </div>
  </div>
);

export default Header;
