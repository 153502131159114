import "../assets/styles/Components/Descripcion.css";

const Descripcion = () => (
  <div className="Descripcion">
    <div className="DescripcionBlur">
      <p>
        El 8vo. Congreso Multidisciplinario Internacional se llevará a cabo del
        8 al 12 de noviembre de 2021 en el Instituto Tecnológico Superior de El
        Mante. Tiene como objetivo generar un espacio para la vinculación e
        intercambio académico, científico y tecnológico en pro del desarrollo
        integral de nuestros futuros ingenieros. Se prepararon una serie de
        actividades de las áreas de la Ingeniería Industrial, Sistemas
        Computacionales, Gestión Empresarial, Innovación Agrícola Sustentable e
        Ingeniería Química, se desarrollan temas relacionados al
        internacionalismo laboral, fomento del deporte, la cultura, inclusión y
        motivación personal. Todas las actividades se ofrecen completamente
        gratuitas y estarán disponibles a través de los diferentes canales de
        comunicación del Tec Mante.
      </p>
    </div>
  </div>
);

export default Descripcion;
