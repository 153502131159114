import { useState } from "react";

import { Card, Button, ListGroup, Modal } from "react-bootstrap";
import ModalA from "../components/ModalA";
import "../assets/styles/sections/Magistrales.css";

const ConfMagis = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    foto,
    actividad,
    persona,
    fecha,
    semestre,
    horario,
    resena,
    aula,
    titulo,
    tipo,
  } = props;
  return (
    <>
      <Card id="tarjeta" style={{ width: "28rem" }}>
        <Card.Img
          id="magistralItem__img"
          as="img"
          variant="top"
          src={require(`../assets/img/conferencistas/${foto}`)}
        />
        <Card.Body>
          <Card.Title>{actividad}</Card.Title>
          <ListGroup.Item>{persona}</ListGroup.Item>
          <ListGroup.Item>{fecha}</ListGroup.Item>
          <ListGroup.Item>{horario}</ListGroup.Item>
        </Card.Body>
        <Card.Footer>
          <Button variant="primary" onClick={handleShow}>
            Detalles
          </Button>
        </Card.Footer>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalA
            foto={foto}
            conferencia={actividad}
            persona={persona}
            titulo={titulo}
            fecha={fecha}
            hora={horario}
            resena={resena}
            aula={aula}
            semestre={semestre}
            tipo={tipo}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfMagis;
