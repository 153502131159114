import { Tabs, Tab } from "react-bootstrap";
import Conf from "./Conf";
import "../assets/styles/Components/Carreras.css";

const Pestanas = () => (
  <div id="carreras" className="CarrerasContainer">
    <Tabs
      defaultActiveKey="profile"
      id="controlled-tab-example"
      className="mb-3 justify-content-center nav pestana"
    >
      <Tab eventKey="I.G.E" title="I.G.E.">
        <Conf name="ige" />
      </Tab>
      <Tab eventKey="I.Q" title="I.Q.">
        <Conf name="iq" />
      </Tab>
      <Tab eventKey="I.I" title="I.IND.">
        <Conf name="ind" />
      </Tab>
      <Tab eventKey="I.S.C" title="I.S.C.">
        <Conf name="isc" />
      </Tab>
      <Tab eventKey="I.I.A.S" title="I.I.A.S.">
        <Conf name="iias" />
      </Tab>
    </Tabs>
  </div>
);

export default Pestanas;
