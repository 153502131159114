import "../assets/styles/Components/ModalA.css";
import { ListGroup } from "react-bootstrap";

const ModalA = (props) => {
  const {
    foto,
    conferencia,
    persona,
    titulo,
    fecha,
    hora,
    resena,
    aula,
    semestre,
    tipo,
  } = props;

  return (
    <div className="ModalC">
      <div className="Imagen">
        {foto.map((imagen) => (
          <img src={require(`../assets/img/conferencistas/${imagen}`)} alt="" />
        ))}
      </div>
      <ListGroup>
        <ListGroup.Item className="conferencia">
          {`Actividad: ${conferencia}`}
        </ListGroup.Item>
        <ListGroup.Item className="persona">
          {`Impartida por: ${persona}`}
        </ListGroup.Item>
        <ListGroup.Item className="titulo">{titulo}</ListGroup.Item>
        <ListGroup.Item className="fecha">{`El dia: ${fecha}`}</ListGroup.Item>
        <ListGroup.Item className="hora">{`A las: ${hora}`}</ListGroup.Item>
        <ListGroup.Item className="tipo">{`Tipo: ${tipo}`}</ListGroup.Item>
        <ListGroup.Item className="semestre">
          {`Para: ${semestre}`}
        </ListGroup.Item>

        {resena.length > 5 ? (
          <ListGroup.Item className="resena">
            Descripcion: {resena}`
          </ListGroup.Item>
        ) : (
          <></>
        )}

        <ListGroup.Item className="aula">
          <p>Impartido en:</p>
          {aula.length > 10 ? (
            <a
              href={aula}
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Ir al Enlace
            </a>
          ) : (
            <p>{aula}</p>
          )}
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default ModalA;
