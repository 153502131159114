import Footer from "./components/Footer";
import Home from "./sections/Home";
import Pestanas from "./sections/Pestanas";
import Magistrales from "./sections/Magistrales";
import Navbar from "./components/Navbar";
function App() {
  return (
    <div id="app">
      <Navbar />
      <Home />
      <Magistrales />
      <Pestanas />
      <Footer />
    </div>
  );
}

export default App;
