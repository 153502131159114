import Congreso1 from "../assets/img/congreso1.jpeg";
import Congreso2 from "../assets/img/congreso2.jpeg";
import Congreso3 from "../assets/img/congreso3.jpeg";
import Congreso4 from "../assets/img/congreso4.jpeg";
import Congreso5 from "../assets/img/congreso5.jpg";
import Congreso6 from "../assets/img/congreso6.jpg";

import Carousel from "react-bootstrap/Carousel";

import "../assets/styles/Components/Slider.css";

const Slider = () => (
  <div className="SliderContainer">
    <div className="Slider">
      <Carousel>
        <Carousel.Item className="Item">
          <img className="d-block w-100" src={Congreso1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Congreso2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Congreso3} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Congreso4} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Congreso5} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Congreso6} alt="Third slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  </div>
);

export default Slider;
