import congreso from "../assets/img/congreso.png";
import educacion from "../assets/img/educacion.png";
import itsm from "../assets/img/itsmW.png";
import tam from "../assets/img/tam.png";
import tecnm from "../assets/img/tecnm.png";

import "../assets/styles/Components/Footer.css";
const Footer = () => (
  <div className="footer">
    <img src={tecnm} alt="" />
    <img src={tam} alt="" />
    <img id="ed" src={educacion} alt="" />
    <img src={itsm} alt="" />
    <img src={congreso} alt="" id="con" />
  </div>
);

export default Footer;
