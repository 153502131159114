import { Navbar, Container, Nav } from "react-bootstrap";

import LogoITSM from "../assets/img/itsmW.png";
import LogoTam from "../assets/img/tam.png";
import LogoTecNM from "../assets/img/tecnm.png";

import "../assets/styles/Components/Navbar.css";

const NavbarComponent = () => {
  return (
    <Navbar
      bg="*"
      expand="lg"
      className="navheader"
      sticky="top"
      variant="dark"
    >
      <Container>
        <Navbar.Brand href="#inicio">
          <img className="nav-logo" src={LogoTecNM}></img>
          <img className="nav-logo" src={LogoTam}></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Navbar.Text>
            <Nav className="me-auto" id="containerlink">
              <Nav.Link id="letrasNav" href="#inicio">
                Inicio
              </Nav.Link>
              <Nav.Link id="letrasNav" href="#magistrales">
                Magistrales
              </Nav.Link>
              <Nav.Link id="letrasNav" href="#carreras">
                Carreras
              </Nav.Link>
            </Nav>
          </Navbar.Text>
          <img className="nav-logo" src={LogoITSM}></img>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
