import ConfMagis from "../components/ConfMagis";

import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../assets/styles/sections/Magistrales.css";

import conferencias from "../../conferencias.json";

class Magistrales extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, error: null, data: [] };
  }

  componentDidMount() {
    this.setState({ loading: false, data: conferencias });
  }

  render() {
    if (this.state.error) {
      return <div>Error</div>;
    } else if (this.state.loading) {
      return <div>Cargando</div>;
    } else {
      return (
        <div id="magistrales" className="magistrales__container">
          <div className="magistrales__banner">
            <h1 className="magistrales__title h1">Conferencias magistrales</h1>
          </div>
          <Container className="magistrales">
            <Row className="justify-content-md-center justify-content-xs-center">
              {this.state.data.magistrales.map((item, idx) => (
                <Col
                  key={idx}
                  xs={12}
                  md={6}
                  lg={4}
                  className="magistrales__col"
                >
                  <ConfMagis
                    key={item.id}
                    foto={item.foto}
                    actividad={item.actividad}
                    persona={item.conferencista}
                    fecha={item.fecha}
                    semestre={item.semestre}
                    horario={item.horario}
                    resena={item.resena}
                    aula={item.aula}
                    titulo={item.titulo}
                    tipo={item.tipo}
                  ></ConfMagis>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      );
    }
  }
}

export default Magistrales;
